import { UserWarningTypes } from '@app/components/userWarning/interface';
import { getCategories } from '@app/services/categories';
import { handleMainMenuItems } from '@app/utils/helpers/productHelpers';
import { getUser, getUserLanguage } from '@app/utils/helpers/userHelpers';
let loadingMainMenu = false;
export async function loadCategories(ignoreCondition, setIsCategoriesLoading, setCategories) {
    if (ignoreCondition === void 0) {
        ignoreCondition = false;
    }
    if (!localStorage.getItem('categories') || ignoreCondition) {
        setIsCategoriesLoading(true);
        try {
            const categoryResponse = await getCategories();
            setCategories(categoryResponse.data.root.taxons);
            const now = new Date().getTime();
            localStorage.setItem('categories-creation-date', now.toString());
            localStorage.setItem('categories', JSON.stringify(categoryResponse.data.root.taxons));
        }
        catch (error) { }
        finally {
            setIsCategoriesLoading(false);
        }
        return;
    }
    const taxons = JSON.parse(localStorage.getItem('categories') || '{}');
    setCategories(taxons);
}
export async function loadMainMenus(ignoreCondition, setIsPageLoading, setMainMenus) {
    if (ignoreCondition === void 0) {
        ignoreCondition = false;
    }
    if (typeof localStorage === 'undefined') {
        return;
    }
    if (loadingMainMenu) {
        return;
    }
    if (!localStorage.getItem('pages') || ignoreCondition) {
        loadingMainMenu = true;
        setIsPageLoading(true);
        try {
            const pages = await handleMainMenuItems();
            const now = new Date().getTime();
            localStorage.setItem('pages-creation-date', now.toString());
            setMainMenus(pages);
        }
        catch (error) { }
        finally {
            loadingMainMenu = false;
            setIsPageLoading(false);
        }
        return;
    }
    const pages = JSON.parse(localStorage.getItem('pages') || '[]');
    setMainMenus(pages);
}
export function handleLanguageChange(setIsPageLoading, setMainMenus, setIsCategoriesLoading, setCategories) {
    var _ref;
    const currentLanguage = getUserLanguage();
    const pages = localStorage.getItem('pages') && JSON.parse(localStorage.getItem('pages'));
    const categories = (_ref = []) !== null && _ref !== void 0 ? _ref : localStorage.getItem('categories') && JSON.parse(localStorage.getItem('categories'));
    if (pages && pages.length > 0 && currentLanguage !== pages[0].language) {
        loadMainMenus(true, setIsPageLoading, setMainMenus);
    }
    if (categories && categories.length > 0
    // &&currentLanguage !== categories[0].language
    ) {
        loadCategories(true, setIsCategoriesLoading, setCategories);
    }
}
export function handleBlockedUser(setUserWarningType, setShowUserWarning) {
    return getUser().then(user => {
        if (user) {
            if (user.is_blocked) {
                setUserWarningType(UserWarningTypes.USER_BLOCKED);
                setShowUserWarning(true);
                return;
            }
            if (user.exceed_limit) {
                setUserWarningType(UserWarningTypes.USER_EXCEEDED_PURCHASE_LIMIT);
                setShowUserWarning(true);
                return;
            }
            setShowUserWarning(false);
        }
    });
}
// export async function handleMainMenuLink(
//   setIsPageLoading: any,
//   setMainMenus: any
// ) {
//   const needsUpdate = await checkForUpates();
//   if (needsUpdate) {
//     loadMainMenus(false, setIsPageLoading, setMainMenus);
//   }
// }
// export function onMainMenuClick(
//   url: string,
//   data: any,
//   setIsPageLoading: any,
//   setMainMenus: any
// ) {
//   handleMainMenuLink(setIsPageLoading, setMainMenus);
//   goTo(url, false, data);
// }
