import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-chained-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getUserLanguage } from '@utils/helpers/userHelpers';
const handleLanguage = () => {
    var _getUserLanguage;
    return (_getUserLanguage = getUserLanguage()) !== null && _getUserLanguage !== void 0 ? _getUserLanguage : 'en';
};
let initSettings = {
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false // not needed for react!!
    },
    react: {
        useSuspense: false,
        bindI18n: 'languageChanged loaded'
    },
    lng: handleLanguage(),
    detection: {
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
        lookupQuerystring: 'lng',
        lookupCookie: 'lng',
        lookupLocalStorage: 'lng',
        cookieMinutes: 10,
        cookieDomain: process.env.COOKIE_DOMAIN,
        cookieOptions: {
            path: '/',
            sameSite: 'strict'
        }
    }
};
if (typeof localStorage !== 'undefined' && typeof window !== 'undefined') {
    initSettings = {
        ...initSettings,
        backend: {
            backends: [LocalStorageBackend, HttpApi],
            backendOptions: [{
                    prefix: 'i18next_res_',
                    expirationTime: 7 * 24 * 60 * 60 * 1000,
                    defaultVersion: 'v1.0',
                    versions: {
                        ar: 'v1.0',
                        en: 'v1.0'
                    },
                    store: typeof localStorage !== 'undefined' && typeof window !== 'undefined' ? window.localStorage : ''
                }, {
                    loadPath: "/api/v1/static_pages?language={{lng}}",
                    parse: function (data) {
                        return JSON.parse(data).data;
                    }
                }],
            type: 'backend',
            crossDomain: true,
            allowMultiLoading: false,
            init: {
                mode: 'no-cors',
                credentials: 'include',
                cache: 'default',
                withCredentials: true
            }
        }
    };
}
i18next.use(LanguageDetector).use(Backend).init(initSettings);
i18next.languages = ['ar', 'en'];
export default i18next;
